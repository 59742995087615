.card {
  color: $primary-purple;
  font-size: 16px;

  /deep/ .header {
    display: none;
  }

  /deep/ .content {
    text-align: center;
    padding: 65px 140px 47px;
    line-height: 1.88;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 40px;
      line-height: 1.5;
    }

    .form_button .el-button {
      margin-top: 40px;
      line-height: 1.5;
    }
  }
}

@media (max-width: 768px) {
  .card /deep/ .content {
    padding: 40px 24px;
  }
}

@media (max-width: 550px) {
  .card {
    /deep/ .content {
      padding: 40px 24px;
      text-align: left;

      p {
        font-size: 16px;
      }
      .form_button {
        text-align: center;
      }
    }
  }
}
